import { ActionTree, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { getCountryAddress, getOrderLibrary, saveCountryAddress } from "@/libs/localStorage";
import data from "@/assets/json/vn.json";
import ghtkData from "@/assets/json/ghtk.json";
import { authService } from "@/services";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET_DARK_MODE](
    { commit }: AugmentedActionContext,
    darkMode: boolean
  ): void;

  [ActionTypes.SET_COUNTRY_ADDRESS]({ commit }: AugmentedActionContext): void;

  [ActionTypes.SET_ORDER_LIBRARY]({ commit }: AugmentedActionContext): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET_DARK_MODE]({ commit }, darkMode: boolean) {
    commit(MutationTypes.SET_DARK_MODE, darkMode);
  },
  [ActionTypes.SET_COUNTRY_ADDRESS]({ commit }) {
    const userInfo = authService.getUserInfoLocal();
    const shippingCode = userInfo?.shippingCode || "jt";
    let countryAddresses = getCountryAddress(shippingCode);
    if (countryAddresses.length === 0) {
      // eslint-disable-next-line
      // @ts-ignore
      countryAddresses = shippingCode === "BEST" ? ghtkData : data;
      saveCountryAddress(shippingCode, countryAddresses);
    }
    commit(MutationTypes.SET_COUNTRY_ADDRESS, countryAddresses);
  },
  [ActionTypes.SET_ORDER_LIBRARY]({ commit }) {
    const orderLibrary = getOrderLibrary();
    commit(MutationTypes.SET_ORDER_LIBRARY, orderLibrary);
  }
};

import { GENERAL_ADDRESS, GHTK_ADDRESS, ORDER_LIBRARY } from "@/configs/constants";
import { CountryAddress } from "@/configs/jtTypes";
import { Order } from "@/configs/orders";

export const saveCountryAddress = (shippingCode: string, address: CountryAddress[]): void => {
  if (shippingCode === "BEST") {
    localStorage.removeItem(GENERAL_ADDRESS);
    localStorage.setItem(GHTK_ADDRESS, JSON.stringify(address));
  } else {
    localStorage.removeItem(GHTK_ADDRESS);
    localStorage.setItem(GENERAL_ADDRESS, JSON.stringify(address));
  }
};

export const getCountryAddress = (shippingCode: string): CountryAddress[] => {
  const address = localStorage.getItem(shippingCode === "BEST" ? GHTK_ADDRESS : GENERAL_ADDRESS);
  try {
    return JSON.parse(address ?? "");
  } catch (_) {
  }
  return [];
};

export const setOrderLibrary = (orders: Order[]): void => {
  localStorage.setItem(ORDER_LIBRARY, JSON.stringify(orders));
};

export function getOrderLibrary(): Order[] {
  const result = localStorage.getItem(ORDER_LIBRARY);
  try {
    if (result) {
      const items: Order[] = JSON.parse(result);
      return items;
    }
  } catch (_) {
  }
  return [];
}

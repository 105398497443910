import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";
import { CountryAddress } from "@/configs/jtTypes";
import { Order } from "@/configs/orders";
import { setOrderLibrary } from "@/libs/localStorage";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_DARK_MODE](state: S, darkMode: boolean): void;
  [MutationTypes.SET_COUNTRY_ADDRESS](state: S, countryAddresses: CountryAddress[]): void;
  [MutationTypes.SET_ORDER_LIBRARY](state: S, orderLibrary: Order[]): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_DARK_MODE](state: LocalState, darkMode: boolean) {
    state.darkMode = darkMode;
  },
  [MutationTypes.SET_COUNTRY_ADDRESS](state: LocalState, countryAddresses: CountryAddress[]) {
    state.countryAddresses = countryAddresses;
  },
  [MutationTypes.SET_ORDER_LIBRARY](state: LocalState, orderLibrary: Order[]) {
    state.orderLibrary = orderLibrary;
    setOrderLibrary(orderLibrary)
  },
};


import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: "ball-triangle",
    },
    color: {
      type: String,
      default: "#db0000",
    },
  },
});

import { App } from "vue";
import Chart from "./chart/Main.vue";
import Litepicker from "./litepicker/Main.vue";
import Tippy from "./tippy/Main.vue";
import TippyContent from "./tippy-content/Main.vue";
import LoadingIcon from "./loading-icon/Main.vue";
import * as featherIcons from "@zhuowenli/vue-feather-icons";

export default (app: App): void => {
  app.component("Chart", Chart);
  app.component("Litepicker", Litepicker);
  app.component("Tippy", Tippy);
  app.component("TippyContent", TippyContent);
  app.component("LoadingIcon", LoadingIcon);

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = "24";
    app.component(key, icon);
  }
};

import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { authService } from ".";

export const client = axios.create({
  baseURL:
    process.env.VUE_APP_BASE_API_URL || "https://api.s-vip.vn/api",
  timeout: 0,
});

const getAuthorization = () => {
  return authService.isLoggedIn()
    ? `Bearer ${authService.getAccessToken()}`
    : "";
};

const requestInterceptor = (request: AxiosRequestConfig) => {
  request.headers.Authorization = getAuthorization();
  return request;
};

const responseSuccessInterceptor = (response: AxiosResponse) => {
  return response;
};

const responseErrorInterceptor = (error: AxiosError) => {
  // Do something with response error - logging bla bla
  if (error.response?.status === 403 || error.response?.status === 401) {
    authService.logout()
    window.location.href = '/login'
  }
  return Promise.reject(error);
};

client.interceptors.request.use(requestInterceptor);
client.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);

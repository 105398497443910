import { COOKIES, GENERAL_ADDRESS, LOCALSTORAGE, ORDER_LIBRARY } from "@/configs/constants";

import { LoginResponse, UserProfile } from "@/configs/jtTypes";
import { client } from "./client";
import Cookies from "js-cookie";
import { AxiosResponse } from "axios";

export const authService = {
  isLoggedIn(): boolean {
    return !!Cookies.get(COOKIES.ACCESS_TOKEN);
  },
  getAccessToken(): string {
    return <string>Cookies.get(COOKIES.ACCESS_TOKEN);
  },
  setUserInfo(info: UserProfile): void {
    localStorage.setItem(LOCALSTORAGE.USER_INFO, JSON.stringify(info));
  },
  getUserInfoLocal(): UserProfile | undefined {
    const result = localStorage.getItem(LOCALSTORAGE.USER_INFO);
    try {
      if (result) {
        return JSON.parse(result);
      }
    } catch (_) {
    }
    return undefined;
  },
  getUserInfoServer(): Promise<AxiosResponse<any>> {
    return client.post("/auth/me").then((res) => {
      this.setUserInfo(res.data);
      return res.data;
    });
  },
  login(userName: string, password: string): Promise<void | AxiosResponse<any>> {
    return client.post("/auth/login", { userName, password }).then((res) => {
      const data: LoginResponse = res.data;
      const expireDate = new Date(new Date().getTime() + data.expiresIn * 1000);
      Cookies.set(COOKIES.ACCESS_TOKEN, data.accessToken, {
        expires: expireDate
      });
    });
  },
  logout(): void {
    localStorage.removeItem(LOCALSTORAGE.USER_INFO);
    localStorage.removeItem(GENERAL_ADDRESS);
    localStorage.removeItem(ORDER_LIBRARY);
    Cookies.remove(COOKIES.ACCESS_TOKEN);
  },
  changePassword(data: any): any {
    return client.post("/auth/change-password", data).then((res) => {
      const data: LoginResponse = res.data;
      const expireDate = new Date(new Date().getTime() + data.expiresIn * 1000);
      Cookies.set(COOKIES.ACCESS_TOKEN, data.accessToken, {
        expires: expireDate
      });
    });
  }
};

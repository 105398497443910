// Create initial state
export const state = {
  menu: [
    {
      icon: "HomeIcon",
      pageName: "side-menu-dashboard",
      title: "Trang chủ",
    },
    {
      icon: "BoxIcon",
      pageName: "my-order",
      title: "Đơn hàng của tôi",
      subMenu: [
        {
          icon: "ChevronRightIcon",
          pageName: "order-create",
          title: "Tạo đơn hàng",
        },
        {
          icon: "ChevronRightIcon",
          pageName: "order-import",
          title: "Lên đơn hàng loạt",
        },
        {
          icon: "ChevronRightIcon",
          pageName: "order-query",
          title: "Tra cứu đơn hàng",
        },
      ],
    },
    {
      icon: "TruckIcon",
      pageName: "setting",
      title: "Quản lý",
      subMenu: [
        {
          icon: "ChevronRightIcon",
          pageName: "order-waybill",
          title: "Vận đơn của tôi",
        },
        {
          icon: "ChevronRightIcon",
          pageName: "money-line",
          title: "Quản lý dòng tiền",
        },
        {
          icon: "ChevronRightIcon",
          pageName: "partsign",
          title: "Giao  1 phần",
        },
      ],
    },
    {
      icon: "SlackIcon",
      pageName: "personal",
      title: "Cài đặt cửa hàng",
      subMenu: [
        {
          icon: "ChevronRightIcon",
          pageName: "change-password",
          title: "Đổi mật khẩu",
        },
        {
          icon: "ChevronRightIcon",
          pageName: "sender-address",
          title: "Địa chỉ gửi hàng",
        },
      ],
    },
    {
      icon: "MapIcon",
      pageName: "order-tracking",
      title: "Tra hành trình",
    }
  ],
  menuChildren: [
    {
      icon: "BoxIcon",
      pageName: "my-order",
      title: "Đơn hàng của tôi",
      subMenu: [
        {
          icon: "ChevronRightIcon",
          pageName: "order-create",
          title: "Tạo đơn hàng",
        },
        {
          icon: "ChevronRightIcon",
          pageName: "order-import",
          title: "Lên đơn hàng loạt",
        },
        {
          icon: "ChevronRightIcon",
          pageName: "order-query",
          title: "Tra cứu đơn hàng",
        },
      ],
    },
    {
      icon: "TruckIcon",
      pageName: "setting",
      title: "Quản lý",
      subMenu: [
        {
          icon: "ChevronRightIcon",
          pageName: "order-waybill",
          title: "Vận đơn của tôi",
        },
        {
          icon: "ChevronRightIcon",
          pageName: "partsign",
          title: "Giao  1 phần",
        },
      ],
    },
    {
      icon: "SlackIcon",
      pageName: "personal",
      title: "Cài đặt cửa hàng",
      subMenu: [
        {
          icon: "ChevronRightIcon",
          pageName: "change-password",
          title: "Đổi mật khẩu",
        },
      ],
    },
    {
      icon: "MapIcon",
      pageName: "order-tracking",
      title: "Tra hành trình",
    }
  ],
  menuCare: [
    {
      icon: "HomeIcon",
      pageName: "side-menu-dashboard",
      title: "Trang chủ",
    },
    {
      icon: "BoxIcon",
      pageName: "order-care",
      title: "Tra cứu đơn hàng",
    },
    {
      icon: "TruckIcon",
      pageName: "partsign",
      title: "Giao  1 phần",
    },
    {
      icon: "MapIcon",
      pageName: "order-tracking",
      title: "Tra hành trình",
    },
  ],
};

// Declare state
export type State = typeof state;

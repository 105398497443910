import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { defineAsyncComponent } from "vue";
import { authService } from "@/services";

const SideMenu = defineAsyncComponent(() => import("../layouts/side-menu/Main.vue"));
const Dashboard = defineAsyncComponent(() => import("../views/dashboard/Main.vue"));
const Login = defineAsyncComponent(() => import("../views/login/Main.vue"));
const ErrorPage = defineAsyncComponent(() => import("../views/error-page/Main.vue"));
const ChangePassword = defineAsyncComponent(() => import("../views/change-password/Main.vue"));
const Tabulator = defineAsyncComponent(() => import("../views/tabulator/Main.vue"));
const OrderQuery = defineAsyncComponent(() => import("../views/order-query/Main.vue"));
const OrderCare = defineAsyncComponent(() => import("../views/order-care/Main.vue"));
const OrderWaybill = defineAsyncComponent(() => import("../views/order-waybill/Main.vue"));
const OrderTracking = defineAsyncComponent(() => import("../views/order-tracking/Main.vue"));
const OrderImport = defineAsyncComponent(() => import("../views/order-import/Main.vue"));
const OrderCreate = defineAsyncComponent(() => import("../views/order-create/Main.vue"));
const SenderAddress = defineAsyncComponent(() => import("../views/sender-address/Main.vue"));
const MoneyLine = defineAsyncComponent(() => import("../views/money-line/Main.vue"));
const PartSign = defineAsyncComponent(() => import("../views/partsign/Main.vue"));
const Test = defineAsyncComponent(() => import("../views/test.vue"));

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "",
        name: "side-menu-dashboard",
        component: Dashboard,
      },
      {
        path: "tra-cuu-don-hang",
        name: "order-query",
        component: OrderQuery,
      },
      {
        path: "care-don",
        name: "order-care",
        component: OrderCare,
      },
      {
        path: "tra-hanh-trinh",
        name: "order-tracking",
        component: OrderTracking,
      },
      {
        path: "len-don-hang-loat",
        name: "order-import",
        component: OrderImport,
      },
      {
        path: "tao-don-le",
        name: "order-create",
        component: OrderCreate,
        props: true,
      },
      {
        path: "doi-mat-khau",
        name: "change-password",
        component: ChangePassword,
      },
      {
        path: "dia-chi-gui-hang",
        name: "sender-address",
        component: SenderAddress,
      },
      {
        path: "van-don-cua-toi",
        name: "order-waybill",
        component: OrderWaybill,
      },
      {
        path: "quan-ly-dong-tien",
        name: "money-line",
        component: MoneyLine,
      },
      {
        path: "giao-hang-mot-phan",
        name: "partsign",
        component: PartSign,
      },
      {
        path: "test",
        name: "test",
        component: Test,
      },
      {
        path: "tabulator",
        name: "das-tabulator",
        component: Tabulator,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage,
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/register"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = authService.isLoggedIn();

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  const user = authService.getUserInfoLocal();
  const notPermitted = ["money-line", "sender-address", 'side-menu-dashboard'];
  const careNotPermitted = [
    "money-line",
    "sender-address",
    "order-create",
    "order-import",
    "change-password",
    "order-query",
    "order-waybill",
  ];

  console.log(<string>to?.name);
  if (user?.role === "CHILDREN" && notPermitted.includes(<string>to?.name)) {
    return next("/error-page");
  }

  if (["buivantue1", "buivantue2"].includes(user?.userName || "") && <string>to?.name === "money-line") {
    return next("/error-page");
  }

  if (user?.role === "CARE" && careNotPermitted.includes(<string>to?.name)) {
    return next("/error-page");
  }

  next();
});

export default router;

import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";
import { CountryAddress } from "@/configs/jtTypes";
import { Order } from "@/configs/orders";

// Getters types
export type Getters = {
  darkMode(state: LocalState): boolean;
  countryAddresses(state: LocalState): CountryAddress[];
  orderLibrary(state: LocalState): Order[];
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  darkMode: (state) => {
    return state.darkMode;
  },
  countryAddresses: (state) => {
    return state.countryAddresses;
  },
  orderLibrary: (state) => {
    return state.orderLibrary;
  },
};

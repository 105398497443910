import { createStore } from "vuex";
import { main, Store as MainStore, State as MainState } from "./main";

import {
  sideMenu,
  Store as SideMenuStore,
  State as SideMenuState,
} from "./side-menu";

export type State = {
  main: MainState;
  sideMenu: SideMenuState;
};

export type Store = MainStore<Pick<State, "main">> &
  SideMenuStore<Pick<State, "sideMenu">>;

export const store = createStore({
  modules: {
    main,
    sideMenu,
  },
});

export function useStore(): Store {
  return store as Store;
}

export default store;

// Create initial state
import { CountryAddress } from "@/configs/jtTypes";
import { Order } from "@/configs/orders";

export const state = {
  darkMode: false,
  countryAddresses: [] as CountryAddress[],
  orderLibrary:[] as Order[],
};

// Declare state
export type State = typeof state;

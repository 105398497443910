export const LOCALSTORAGE = {
  USER_INFO: "USER_INFO"
};

export const COOKIES = {
  ACCESS_TOKEN: "ACCESS_TOKEN"
};

export const GENERAL_ADDRESS = "GENERAL_ADDRESS";
export const GHTK_ADDRESS = "GHTK_ADDRESS";
export const ORDER_LIBRARY = "ORDER_LIBRARY";

export const DATE_FORMAT = "DD/MM/YYYY";
